.maindiv{
  scroll-behavior: smooth;
}
.test{
    background-color: #FF0000;
}
.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 30px;
  }
.cardOnMessage {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 40px;
    width: 60%;
    justify-content: center;
    align-content: center;
    border-radius: 10px;
  }
.input-style {
   height:30px;
   width: 97%;
   border-width: 1px;
   border-color: #f5f5f5;
   padding: 7px;
   margin-top: 20px;
   border-radius: 6px;
   color: #495057;
   background-color: #fff;
   background-clip:padding-box;
   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;;
  }
.textarea-style {
   height:100px;
   width: 97%;
   border-width: 1px;
   border-color: #f5f5f5;
   padding: 7px;
   margin-top: 20px;
   border-radius: 6px;
  }
.formStyle {
   justify-content: center;
   align-items: center;
  }